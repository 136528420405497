import React from "react";

import { useState, useEffect } from "react";
import { X } from "lucide-react";
import styles from "./CheckoutModal.module.css";
import { countries } from "./countries";
import { Link } from "react-router-dom";
import RenderReviewStep from "./renderReviewStep";
import { BASE_URL, GET_SITESETTINGS } from "./../../constant/constants";

const CheckoutModal = ({
  isOpen,
  onClose,
  tourName,
  selectedDate,
  selectedTime,
  total,
  participants,
  adultPrice,
  childPrice,
  youthPrice,
  duration,
  tourImage,
  tourID,
}) => {
  //LOGO
  const [siteSetting, setSiteSetting] = useState({});

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);

  const [step, setStep] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({
    code: "GB",
    dial_code: "+44",
    flag: "gb",
    name: "United Kingdom",
    label: "United Kingdom",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "+44",
    newsletter: false,
    gender: "",
    nationality: "United Kingdom",
    dateOfBirth: "",
    passportId: "",
    acceptTerms: false,
    payWithCash: false,
    payWithStripe: false,
  });
  const [showDetails, setShowDetails] = useState(true);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Special handling for date of birth to format as DD/MM/YYYY
    if (name === "dateOfBirth" && type === "date") {
      const parts = value.split("-");
      if (parts.length === 3) {
        const [year, month, day] = parts;
        const formattedDate = `${day}/${month}/${year}`;
        setFormData((prev) => ({
          ...prev,
          [name]: formattedDate,
        }));
        return;
      }
    }

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.code === e.target.value);
    setSelectedCountry({
      ...country,
      label: country.label,
    });
    setFormData((prev) => ({
      ...prev,
      phone: country.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleSecureCheckout = () => {
    console.log("Proceeding to secure checkout", { formData, selectedCountry });
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatTime = (time) => {
    if (!time) return "";
    return time;
  };

  const getCheckoutButtonText = () => {
    if (formData.payWithCash) return "Proceed with Cash Payment";
    if (formData.payWithStripe) return "Pay with Stripe";
    return "Select a payment method";
  };

  const handlePaymentMethodChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      payWithCash: name === "payWithCash" ? checked : false,
      payWithStripe: name === "payWithStripe" ? checked : false,
    }));
  };

  // Calculate max date (must be at least 18 years old)
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);
  const maxDateString = maxDate.toISOString().split("T")[0];

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div className={styles.headerContent}>
            <img
              style={{
                width: "150px",
              }}
              src={`${BASE_URL}${siteSetting.footer_logo}`}
              alt="blog"
              loading="lazy"
            />

            <div className={styles.progressContainer}>
              <div className={styles.progressBar}>
                <div className={styles.progressStep}>
                  <span
                    className={`${styles.stepText} ${
                      step >= 1 ? styles.active : ""
                    }`}
                  >
                    Contact details
                  </span>
                  <div
                    className={`${styles.stepNumber} ${
                      step >= 1 ? styles.active : ""
                    }`}
                  >
                    1
                  </div>
                </div>
                <div className={styles.progressLine}>
                  <div
                    className={`${styles.line} ${
                      step === 2 ? styles.active : ""
                    }`}
                  ></div>
                </div>
                <div className={styles.progressStep}>
                  <span
                    className={`${styles.stepText} ${
                      step === 2 ? styles.active : ""
                    }`}
                  >
                    Review
                  </span>
                  <div
                    className={`${styles.stepNumber} ${
                      step === 2 ? styles.active : ""
                    }`}
                  >
                    2
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.circularProgress}>
              <div className={styles.progressRing}></div>
              <div
                className={`${styles.progressRingFill} ${
                  step === 2 ? styles.step2 : ""
                }`}
              ></div>
              <div className={styles.progressText}>{step} of 2</div>
            </div>

            <div className={styles.headerRight}>
              <select className={styles.currencySelect}>
                <option value="GBP">£ GBP</option>
              </select>
              <button onClick={onClose} className={styles.closeButton}>
                <X size={24} />
              </button>
            </div>
          </div>
        </div>

        <div className={styles.modalBody}>
          {step === 1 ? (
            <>
              <div className={styles.formSection}>
                <h2 className={styles.formTitle}>
                  Main traveller's contact details
                </h2>

                <form onSubmit={handleSubmit}>
                  <div className={styles.fromborder}>
                    <div className={styles.formGrid}>
                      <div className={styles.formGroup}>
                        <label>
                          First name <span className={styles.required}>*</span>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>
                          Last name <span className={styles.required}>*</span>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>
                          Your email address{" "}
                          <span className={styles.required}>*</span>
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>
                          Phone number{" "}
                          <span className={styles.required}>*</span>
                        </label>
                        <div className={styles.phoneInput}>
                          <div className={styles.countrySelect}>
                            <div className={styles.flagContainer}>
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                            </div>
                            <span className={styles.selectedCountryCode}>
                              {selectedCountry.code}
                            </span>
                            <select
                              value={selectedCountry.code}
                              onChange={handleCountryChange}
                              className={styles.countrySelect}
                            >
                              {countries.map((country) => (
                                <option key={country.code} value={country.code}>
                                  {`${country.label} (${country.value})`}
                                </option>
                              ))}
                            </select>
                          </div>
                          <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                            placeholder="Phone number"
                          />
                        </div>
                      </div>
                    </div>

                    <div className={styles.newsletterCheckbox}>
                      <input
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        checked={formData.newsletter}
                        onChange={handleInputChange}
                      />
                      Email me with news and offers
                    </div>

                    <div className={styles.formGrid}>
                      <div className={styles.formGroup}>
                        <label>
                          Gender <span className={styles.required}>*</span>
                        </label>
                        <select
                          name="gender"
                          value={formData.gender}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label>
                          Nationality <span className={styles.required}>*</span>
                        </label>
                        <select
                          name="nationality"
                          value={formData.nationality}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="United Kingdom">United Kingdom</option>
                          {/* Add more nationality options here */}
                        </select>
                      </div>
                      <div className={styles.formGroup}>
                        <label>Date of birth</label>
                        <input
                          type="date"
                          name="dateOfBirth"
                          max={maxDateString}
                          onChange={handleInputChange}
                          className={styles.dateInput}
                        />
                      </div>
                      <div className={styles.formGroup}>
                        <label>Passport ID</label>
                        <input
                          type="text"
                          name="passportId"
                          value={formData.passportId}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles.formFooter}>
                    <div className={styles.terms}>
                      <Link to="/terms-and-conditions">
                        Terms And Conditions
                      </Link>
                      <Link to="/cancellation-policy">Cancellation Policy</Link>
                    </div>
                    <button type="submit" className={styles.continueButton}>
                      Continue
                    </button>
                  </div>
                </form>
              </div>
              <div className={styles.orderSummary}>
                <div className={styles.orderDetails}>
                  <h3 style={{ fontFamily: '"Poppins", sans-serif' }}>
                    {tourName}
                  </h3>
                  <p>
                    {formatDate(selectedDate)} - {formatTime(selectedTime)}
                  </p>
                  <div className={styles.orderTotal}>
                    {participants?.adult > 0 && (
                      <div className={styles.totalRow}>
                        <span>Adult×{participants?.adult}</span>
                        <span>
                          £
                          {(participants.adult * Number(adultPrice)).toFixed(2)}
                        </span>
                      </div>
                    )}
                    {participants?.youth > 0 && (
                      <div className={styles.totalRow}>
                        <span>Youth×{participants?.youth}</span>
                        <span>
                          £
                          {(participants.youth * Number(youthPrice)).toFixed(2)}
                        </span>
                      </div>
                    )}
                    {participants?.child > 0 && (
                      <div className={styles.totalRow}>
                        <span>Child×{participants?.child}</span>
                        <span>
                          £
                          {(participants.child * Number(childPrice)).toFixed(2)}
                        </span>
                      </div>
                    )}
                    <div className={styles.totalRow}>
                      <span>Total (GBP)</span>
                      <span>£{total.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <RenderReviewStep
              handleBack={handleBack}
              tourName={tourName}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              participants={participants}
              adultPrice={adultPrice}
              childPrice={childPrice}
              youthPrice={youthPrice}
              duration={duration}
              formData={formData}
              handleInputChange={handleInputChange}
              handlePaymentMethodChange={handlePaymentMethodChange}
              handleSecureCheckout={handleSecureCheckout}
              onClose={onClose}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              total={total}
              tourImage={tourImage}
              tourID={tourID}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutModal;

import React from "react";

import { useState, useEffect, useRef } from "react";
import { Table, Spinner, Alert } from "react-bootstrap";
import { tour_booking_list_by_agent_all } from "./../../constant/constants";

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const tableWrapperRef = useRef(null);

  useEffect(() => {
    const fetchBookings = async () => {
      const ref_id = localStorage.getItem("ref_no");
      if (!ref_id) {
        setError("No reference ID found");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${tour_booking_list_by_agent_all}${ref_id}/`
        );
        if (!response.ok) {
          throw new Error("There is no Bookings");
        }
        const data = await response.json();
        setBookings(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookings();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { current } = tableWrapperRef;
      if (current) {
        const { scrollLeft } = current;
        current.style.setProperty("--table-scroll-left", `${scrollLeft}px`);
      }
    };

    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getPaymentMethod = (booking) => {
    if (booking.payWithCash) return "Cash";
    if (booking.payWithStripe) return "Stripe";
    return "N/A";
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center p-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  console.log("all bookings", bookings);

  return (
    <div className="booking-list-container">
      <h2 className="mb-4">Booking List</h2>
      <div className="table-container">
        <div className="table-wrapper" ref={tableWrapperRef}>
          <Table striped bordered hover className="booking-table">
            <thead>
              <tr>
                <th style={{ width: "50px", minWidth: "50px" }}>ID</th>
                <th style={{ width: "120px", minWidth: "120px" }}>Agent</th>
                <th style={{ width: "400px", minWidth: "400px" }}>Tour</th>
                <th style={{ width: "100px", minWidth: "100px" }}>Traveler</th>
                <th style={{ width: "100px", minWidth: "100px" }}>Status</th>
                <th style={{ width: "100px", minWidth: "100px" }}>
                  Total Price
                </th>
                <th style={{ width: "100px", minWidth: "100px" }}>
                  Total Commission
                </th>
                <th style={{ width: "100px", minWidth: "100px" }}>
                  Commission %
                </th>
                <th style={{ width: "200px", minWidth: "200px" }}>
                  Participants
                </th>
                <th style={{ width: "120px", minWidth: "120px" }}>
                  Selected Date
                </th>
                <th style={{ width: "100px", minWidth: "100px" }}>
                  Selected Time
                </th>
                <th style={{ width: "100px", minWidth: "100px" }}>Duration</th>
                <th style={{ width: "80px", minWidth: "80px" }}>Is Agent</th>

                <th style={{ width: "120px", minWidth: "120px" }}>
                  Payment Method
                </th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td>{booking.id}</td>
                  <td>{`${booking.agent.first_name} ${
                    booking.agent.last_name || ""
                  }`}</td>
                  <td className="tour-name">{booking.tour.name}</td>
                  <td>{booking.traveler}</td>
                  <td>{booking.status}</td>
                  <td>{booking.total_price}</td>
                  <td style={{ textAlign: "center" }}>
                    {booking.total_discount_amount ?? "...."}
                  </td>
                  <td>{booking.discount_percent}</td>
                  <td>
                    Adult: {booking.participants.adult}, <br />
                    Youth: {booking.participants.youth}, <br />
                    Child: {booking.participants.child}
                  </td>
                  <td>{booking.selected_date}</td>
                  <td>{booking.selected_time?.slice(0, 5)}</td>
                  <td>{booking.duration}</td>
                  <td>{booking.is_agent ? "Yes" : "No"}</td>

                  <td>{getPaymentMethod(booking)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      <style jsx>{`
        .booking-list-container {
          padding: 20px;
          height: calc(100vh - 100px); /* Adjust based on your layout */
          display: flex;
          flex-direction: column;
        }

        .table-container {
          flex: 1;
          overflow: hidden;
          position: relative;
        }

        .table-wrapper {
          overflow-y: auto;
          overflow-x: auto;
          height: 100%;
          padding-bottom: 20px; /* Space for the scrollbar */
        }

        .booking-table {
          margin-bottom: 0;
          font-size: 14px;
          white-space: nowrap;
        }

        .booking-table th,
        .booking-table td {
          vertical-align: middle;
          padding: 12px 8px;
          background-color: #fff;
        }

        .tour-name {
          white-space: normal;
          max-width: 400px;
          min-width: 400px;
        }

        /* Sticky header */
        .booking-table thead th {
          position: sticky;
          top: 0;
          background-color: #f8f9fa;
          z-index: 1;
        }

        /* Sticky scrollbar */
        .table-wrapper::after {
          content: "";
          position: sticky;
          bottom: 0;
          left: var(--table-scroll-left, 0);
          width: 100%;
          height: 20px; /* Height of the scrollbar */
          background-color: #f8f9fa;
          z-index: 2;
        }

        .table-wrapper::-webkit-scrollbar {
          height: 20px;
        }

        .table-wrapper::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 10px;
          border: 5px solid #f8f9fa;
        }

        .table-wrapper::-webkit-scrollbar-track {
          background-color: #f8f9fa;
        }

        @media (max-width: 1200px) {
          .booking-table {
            font-size: 13px;
          }
        }
      `}</style>
    </div>
  );
};

export default BookingList;

import React from "react";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TripReview from "./TripReview";
import { GET_CONTENTS_WITHOUT_PAGINATION_UK } from "../../constant/constants";
import AuthGuard from "./../AuthGuard";

const Tours = () => {
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await fetch(GET_CONTENTS_WITHOUT_PAGINATION_UK);
        const data = await response.json();
        const filteredTours = data.menu_items.filter(
          (item) =>
            item.type === "Tours" &&
            item.location !== null && // Exclude items with location: null
            !item.name.includes("Colosseum") &&
            !item.name.includes("TechStore") &&
            !item.name.includes("Capri")
        );
        setTours(filteredTours);
      } catch (error) {
        console.error("Error fetching tours:", error);
      }
    };

    fetchTours();
  }, []);

  return (
    <div className="container mt-4">
      {tours.length > 0 ? (
        <div className="row g-4 ">
          {tours.map((tour) => (
            <div key={tour.id} className="col-auto ">
              <Link
                to={`/tours/${tour.slug || tour.id}`}
                className="text-decoration-none"
              >
                <div className="card h-100 border-0 tour-card">
                  <div className="tour-image-wrapper">
                    <div className="tour-image-container">
                      <img
                        src={tour.cloudflare_image?.[0] || "/placeholder.svg"}
                        className="tour-image"
                        alt={tour.name}
                        loading="lazy"
                      />
                    </div>

                    <div className="price-badge">
                      <div className="position-relative text-white">
                        <div className="price-content">
                          <div className="fw-bold">
                            £ {Number.parseFloat(tour.price || 0).toFixed(2)}
                          </div>
                          <div className="per-person">Per Person</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body px-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-muted small">{tour.duration}</span>
                      <span className="fw-bold small">
                        From £ {Number.parseFloat(tour.price || 0).toFixed(2)}
                      </span>
                    </div>

                    <h5 className="tour-title">{tour.name}</h5>
                    <p className="tour-location">
                      {tour.location || "Location not specified"}
                    </p>

                    <div className="d-flex align-items-center gap-2">
                      <TripReview title={tour.name.toLowerCase()} />
                      <span className="review-count">
                        {tour.reviews || 0} reviews
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No tours available at the moment.</p>
      )}
      <style jsx>{`
        @media (max-width: 768px) {
          /* Adjust for mobile screens */
          .row {
            justify-content: center;
          }
        }
        .tour-card {
          transition: transform 0.2s ease-in-out;
          width: 302px;
          height: 420px;
          margin: 0 auto;
        }

        .tour-card:hover {
          transform: translateY(-5px);
        }

        .tour-image-wrapper {
          position: relative;
          width: 302px;
          height: 302px;
        }

        .tour-image-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .tour-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .tour-card:hover .tour-image {
          transform: scale(1.05);
        }

        .price-badge {
          position: absolute;
          top: 10px;
          width: 110px;
          overflow: hidden;
          transition: width 0.3s ease;
          z-index: 1;
        }
        .price-badge::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
          transition: clip-path 0.3s ease;
        }

        .price-badge .position-relative {
          position: relative;
          z-index: 1;
          width: 100%;
        }

        .price-content {
          padding: 5px 8px;
          text-align: left;
          transition: all 0.3s ease;
        }

        .price-badge:hover {
          width: 140px;
        }

        .price-content .fw-bold {
          font-size: 13px;
        }

        .per-person {
          font-size: 8px;
          text-transform: uppercase;
        }

        .card-body {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }

        .tour-title {
          margin-top: 0.35rem;
          margin-bottom: 0.2rem;
          color: #1b1b1b;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.2;
          font-family: "Poppins", sans-serif;
        }

        .tour-location {
          color: #6c757d;
          font-size: 13px;
          margin-bottom: 0.35rem;
        }

        .review-count {
          color: #6c757d;
          font-size: 12px;
        }

        .small {
          font-size: 12px;
        }
      `}</style>
    </div>
  );
};

export default function ProtectedTours() {
  return (
    <AuthGuard>
      <Tours />
    </AuthGuard>
  );
}

import React, { useEffect } from "react";

import { useState } from "react";
import Participants from "./Participants";
import ChooseDate from "./ChooseDate";
import BookingSummary from "./BookingSummary";
import styles from "./calendar.module.css";
import { get_all_cms_menu_content_image_list_by_menu_name_UK } from './../../constant/constants';

const AgentCalendar = ({
  agentRef,
  tourSlug,
  busId,
  availableDates,
  price,
  adultPrice,
  childPrice,
  youthPrice,
  data,
  data2,
}) => {
  const user_id = localStorage.getItem("user_id");
  const [matchedTourData, setMatchedTourData] = useState(null);
  const [contentImages, setContentImages] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [participants, setParticipants] = useState({
    child: 0,
    adult: 1,
    youth: 0,
  });
  console.log("user_id", user_id);

  const tourName = data2?.name || "Tour Name Not Available";

  const handleParticipantChange = (counts) => {
    setParticipants(counts);
    console.log("Updated participants:", counts); // Add this line for debugging
  };

  const handleDateSelection = (selection) => {
    setSelectedDate(selection.date);
    setSelectedTime(selection.time);
  };

  // Convert availableDates strings to Date objects
  const parsedAvailableDates = availableDates
    ? availableDates.map((dateStr) => new Date(dateStr))
    : [];

  // Fetch content images from API
  useEffect(() => {
    const fetchContentImages = async () => {
      try {
        const response = await fetch(
          get_all_cms_menu_content_image_list_by_menu_name_UK
        );
        const data = await response.json();
        setContentImages(data.content_images);
      } catch (error) {
        console.error("Error fetching content images:", error);
      }
    };

    fetchContentImages();
  }, []);

  // Find matched tour image
  useEffect(() => {
    if (contentImages && data2?.name) {
      const tourImages = contentImages[data2.name];
      if (tourImages && tourImages.length > 0) {
        setMatchedTourData(tourImages[0]);
      }
    }
  }, [contentImages, data2?.name]);
  console.log("images", matchedTourData);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col px-0">
          <div className={styles.bookingColumn}>
            <div className={styles.section}>
              <Participants
                onCountChange={handleParticipantChange}
                adultPrice={adultPrice}
                childPrice={childPrice}
                youthPrice={youthPrice}
              />
            </div>
            <div className={styles.section}>
              <ChooseDate
                onSelectionComplete={handleDateSelection}
                availableDates={parsedAvailableDates}
                price={price}
              />
            </div>
            <div className={styles.section}>
              <BookingSummary
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                participants={participants}
                adultPrice={adultPrice}
                childPrice={childPrice}
                youthPrice={youthPrice}
                tourName={tourName}
                busId={busId}
                duration={data2.duration}
                tourImage={matchedTourData}
                tourID={data2.id}
              />
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
.gradient-text span {
  background: linear-gradient(to right, #fa80b5, rgb(1, 1, 177), #fa80b5);
  -webkit-background-clip: text; /* For WebKit-based browsers */
  -webkit-text-fill-color: transparent; /* For WebKit-based browsers */
}

        `}
      </style>
    </div>
  );
};

export default AgentCalendar;

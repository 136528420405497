import React, { useState } from "react";
import { X, ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import styles from "./CheckoutModal.module.css";
import { BASE_URL } from "./../../constant/constants";

const RenderReviewStep = ({
  handleBack,
  tourName,
  selectedDate,
  selectedTime,
  participants,
  adultPrice,
  childPrice,
  youthPrice,
  duration,
  formData,
  handleInputChange,
  handlePaymentMethodChange,
  onClose,
  showDetails,
  setShowDetails,
  total,
  tourImage,
  tourID,
  realPrice,
  realAdultPrice,
  realChildPrice,
  realYouthPrice,
  selectedCountry,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const is_agent = true;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const currentCurrency = {
    currency: "GBP",
    id: 13,
    name: "Great Britain Pound",
    symbol: "£",
  };

  const formatTime = (time) => {
    if (!time) return "";
    return time;
  };

  const agentRef = localStorage.getItem("ref_no");

  const handleSecureCheckout = async () => {
    // Proceed only if either payment method is selected
    if (!formData.payWithCash && !formData.payWithStripe) return;

    // Set loading state to true
    setIsLoading(true);

    const formattedDate = formatDate(selectedDate);
    const checkoutData = {
      ...formData,
      tourName,
      selectedDate: formattedDate,
      selectedTime,
      total,
      participants,
      adultPrice,
      childPrice,
      youthPrice,
      duration,
      selectedCountry,
      tourImage,
      tourID,
      agentRef,
      currentCurrency,
      realPrice,
      realAdultPrice,
      realChildPrice,
      realYouthPrice,
      is_agent,
      // Set payment method flags based on selection
      payWithCash: formData.payWithCash,
      payWithStripe: formData.payWithStripe,
    };
    console.log("Proceeding to checkout", { checkoutData });

    try {
      const response = await fetch(
        `${BASE_URL}/payments/api/v1/stripe/payment/checkout/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(checkoutData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Checkout response:", result);

      if (formData.payWithStripe && result.session_url) {
        // Open Stripe checkout in new window
        window.open(result.session_url, "_blank");
        // Redirect current window to home
        window.location.href = "/";
      } else if (formData.payWithCash && result.success_url) {
        // Open success page in new window
        window.open(result.success_url, "_blank");
        // Redirect current window to home
        window.location.href = "/";
      } else {
        // If no redirect happened, reset loading state
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      alert("An error occurred during checkout. Please try again.");
      // Reset loading state on error
      setIsLoading(false);
    }
  };

  const getCheckoutButtonText = () => {
    if (isLoading)
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}></div>
          <span>Processing...</span>
        </div>
      );
    if (formData.payWithCash) return "Proceed with Cash Payment";
    if (formData.payWithStripe) return "Proceed with Online Payment";
    return "Select a payment method";
  };

  const dateStr = selectedDate
    ? `${formatDate(selectedDate)} - ${formatTime(selectedTime)}`
    : "";
  const totalAdult = (participants?.adult || 0) * Number(adultPrice);
  const totalChild = (participants?.child || 0) * Number(childPrice);
  const totalYouth = (participants?.youth || 0) * Number(youthPrice);

  return (
    <div className={styles.reviewStep}>
      <button
        onClick={handleBack}
        className={styles.backButton}
        disabled={isLoading}
      >
        <ArrowLeft size={20} />
        Back
      </button>

      <div className={styles.reviewContent}>
        <div className={styles.leftColumn}>
          <div className={styles.bookingDetails}>
            <h2 className={styles.bookingTitle}>You're booking</h2>

            <div className={styles.tourCard}>
              <div className={styles.tourImage}>
                <img
                  src={tourImage || "/placeholder.svg"}
                  alt={tourName}
                  width={500}
                  height={500}
                  className={styles.tourImg}
                />
              </div>
              <div className={styles.tourInfo}>
                <h3 style={{ fontFamily: '"Poppins", sans-serif' }}>
                  {tourName}
                </h3>
                <div className={styles.tourDetailsTable}>
                  <div className={styles.detailColumn}>
                    <div className={styles.columnHeader}>Travellers</div>
                    <div className={styles.columnValue}>
                      {[
                        participants?.adult
                          ? `${participants.adult} Adult`
                          : "",
                        participants?.youth
                          ? `${participants.youth} Youth`
                          : "",
                        participants?.child
                          ? `${participants.child} Child`
                          : "",
                      ]
                        .filter(Boolean) // Remove empty strings
                        .join(", ")}{" "}
                      {/* Join with comma */}
                    </div>
                  </div>
                  <div className={styles.detailColumn}>
                    <div className={styles.columnHeader}>Departure</div>
                    <div className={styles.columnValue}>{dateStr}</div>
                  </div>
                  <div className={styles.detailColumn}>
                    <div className={styles.columnHeader}>Duration</div>
                    <div className={styles.columnValue}>{duration}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.secureCheckout}>
            <h2 className={styles.checkoutTitle}>Secure Checkout</h2>

            <div className={styles.paymentMethods}>
              <div className={styles.paymentOption}>
                <div className={styles.newsletterCheckbox}>
                  <input
                    type="checkbox"
                    id="payWithCash"
                    name="payWithCash"
                    checked={formData.payWithCash}
                    onChange={handlePaymentMethodChange}
                    disabled={isLoading}
                  />
                  <span className={styles.paymentIcon}>💷</span>
                  Pay with Cash
                </div>
              </div>

              <div>
                <div className={styles.newsletterCheckbox}>
                  <input
                    type="checkbox"
                    id="payWithStripe"
                    name="payWithStripe"
                    checked={formData.payWithStripe}
                    onChange={handlePaymentMethodChange}
                    disabled={isLoading}
                  />
                  <span className={styles.paymentIcon}>💳</span>
                  Pay Online
                </div>
              </div>
            </div>

            <div className={styles.termsCheckbox}>
              <div className={styles.newsletterCheckbox}>
                <input
                  type="checkbox"
                  id="acceptTerms"
                  name="acceptTerms"
                  checked={formData.acceptTerms}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </div>
              <label htmlFor="acceptTerms">
                I accept the{" "}
                <Link href="/terms-and-conditions" className={styles.link}>
                  terms and conditions
                </Link>{" "}
                and{" "}
                <Link href="/cancellation-policy" className={styles.link}>
                  cancellation policy
                </Link>
              </label>
            </div>

            <button
              className={`${styles.checkoutButton} ${
                (!formData.payWithCash && !formData.payWithStripe) || isLoading
                  ? styles.disabled
                  : ""
              }`}
              onClick={handleSecureCheckout}
              disabled={
                !formData.acceptTerms ||
                (!formData.payWithCash && !formData.payWithStripe) ||
                isLoading
              }
            >
              {getCheckoutButtonText()}
            </button>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <div className={styles.priceSummaryCard}>
            <div className={styles.summaryHeader}>
              <div className={styles.summaryTitle}>
                <h3 style={{ fontFamily: '"Poppins", sans-serif' }}>
                  {tourName}
                </h3>
                <button
                  onClick={onClose}
                  className={styles.closeButton}
                  disabled={isLoading}
                >
                  <X size={16} />
                </button>
              </div>
              <div className={styles.summaryDate}>{dateStr}</div>
            </div>

            {showDetails && (
              <div className={styles.priceBreakdown}>
                {participants?.adult > 0 && (
                  <div className={styles.priceRow}>
                    <span>Adult×{participants?.adult}</span>
                    <span>£{totalAdult.toFixed(2)}</span>
                  </div>
                )}
                {participants?.youth > 0 && (
                  <div className={styles.priceRow}>
                    <span>Youth×{participants?.youth}</span>
                    <span>£{totalYouth.toFixed(2)}</span>
                  </div>
                )}
                {participants?.child > 0 && (
                  <div className={styles.priceRow}>
                    <span>Child×{participants?.child}</span>
                    <span>£{totalChild.toFixed(2)}</span>
                  </div>
                )}
              </div>
            )}

            <button
              className={styles.toggleButton}
              onClick={() => setShowDetails(!showDetails)}
              disabled={isLoading}
            >
              Show {showDetails ? "less" : "more"}
            </button>

            <div className={styles.totalSection}>
              <div className={styles.priceRow}>
                <span>Items</span>
                <span>£{total.toFixed(2)}</span>
              </div>
              <div className={`${styles.priceRow} ${styles.finalTotal}`}>
                <span>Total (GBP)</span>
                <span>£{total.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .${styles.loadingContainer} {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }

        .${styles.loadingSpinner} {
          width: 20px;
          height: 20px;
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top-color: white;
          animation: spin 1s ease-in-out infinite;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default RenderReviewStep;

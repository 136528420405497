import React from "react";

import { useState } from "react";
import styles from "./BookingSummary.module.css";
import CheckoutModal from "./CheckoutModal";

const BookingSummary = ({
  selectedDate,
  selectedTime,
  participants,
  adultPrice,
  childPrice,
  youthPrice,
  tourName,
  busId,
  duration,
  tourImage,
  tourID,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const calculateTotal = () => {
    const childTotal = (participants?.child || 0) * Number(childPrice);
    const adultTotal = (participants?.adult || 0) * Number(adultPrice);
    const youthTotal = (participants?.youth || 0) * Number(youthPrice);
    return childTotal + adultTotal + youthTotal;
  };

  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const handleCheckout = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Booking Summary</h2>

        <div className={styles.summaryBox}>
          <div className="row">
            <div className="col-7 border-end">
              <h3 className={styles.tourTitle}>{tourName}</h3>

              {participants?.adult > 0 && (
                <div className={styles.priceInfo}>
                  Adult: {participants.adult} x £{Number(adultPrice).toFixed(2)}
                </div>
              )}
              {participants?.youth > 0 && (
                <div className={styles.priceInfo}>
                  Youth: {participants.youth} x £{Number(youthPrice).toFixed(2)}
                </div>
              )}
              {participants?.child > 0 && (
                <div className={styles.priceInfo}>
                  Child: {participants.child} x £{Number(childPrice).toFixed(2)}
                </div>
              )}
            </div>

            <div className="col-5 text-end">
              <div className={styles.timeDisplay}>
                {selectedTime || "--:--"}
              </div>
              <div className={styles.dateDisplay}>
                {selectedDate ? formatDate(selectedDate) : "Select a date"}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.totalSection}>
          <span className={styles.totalLabel}>Total:</span>
          <span className={styles.totalAmount}>
            £{calculateTotal().toFixed(2)}
          </span>
        </div>

        <button
          className={styles.checkoutButton}
          disabled={!selectedDate || !selectedTime || calculateTotal() === 0}
          onClick={handleCheckout}
        >
          Checkout
          <i className="icon icon-shopping-cart"></i>
        </button>
      </div>

      <CheckoutModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tourName={tourName}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        total={calculateTotal()}
        participants={participants}
        adultPrice={adultPrice}
        childPrice={childPrice}
        youthPrice={youthPrice}
        duration={duration}
        tourImage={tourImage}
        tourID={tourID}
      />
    </>
  );
};

export default BookingSummary;

import React from "react";

import { useState } from "react";
import styles from "./calendar.module.css";

const Participants = ({
  onCountChange,
  adultPrice,
  childPrice,
  youthPrice,
}) => {
  const [participants, setParticipants] = useState({
    child: 0,
    adult: 1,
    youth: 0,
  }); // Set adult to 1 by default

  const updateCount = (type, increment) => {
    const newCounts = { ...participants };
    switch (type) {
      case "child":
        newCounts.child = Math.max(0, newCounts.child + increment);
        break;
      case "adult":
        newCounts.adult = Math.max(0, newCounts.adult + increment); // Ensure at least 1 adult
        break;
      case "youth":
        newCounts.youth = Math.max(0, newCounts.youth + increment);
        break;
    }
    setParticipants(newCounts);
    onCountChange(newCounts);
  };

  return (
    <>
      <h2 className={styles.sectionTitle}>Participants</h2>

      <div className={styles.participantsWrapper}>
        {/* Adult */}
        <div className={styles.participantType}>
          <div className={styles.participantInfo}>
            <div className={styles.participantLabel}>Adult</div>
            <div className={styles.ageRange}>Age 18 - 65</div>
            <div className={styles.price}>£{adultPrice}</div>
          </div>
          <div className={styles.counterControls}>
            <button
              className={styles.counterButton}
              onClick={() => updateCount("adult", -1)}
              disabled={participants.adult === 0} // Prevent going below 0
            >
              <span className={styles.buttonIcon}>−</span>
            </button>
            <span className={styles.counterValue}>{participants.adult}</span>
            <button
              className={styles.counterButton}
              onClick={() => updateCount("adult", 1)}
            >
              <span className={styles.buttonIcon}>+</span>
            </button>
          </div>
        </div>

        {/* Youth section - conditional rendering */}
        {youthPrice > 0 && (
          <div className={styles.participantType}>
            <div className={styles.participantInfo}>
              <div className={styles.participantLabel}>Youth</div>
              <div className={styles.ageRange}>Age 13 - 17</div>
              <div className={styles.price}>£{youthPrice}</div>
            </div>
            <div className={styles.counterControls}>
              <button
                className={styles.counterButton}
                onClick={() => updateCount("youth", -1)}
                disabled={participants.youth === 0}
              >
                <span className={styles.buttonIcon}>−</span>
              </button>
              <span className={styles.counterValue}>{participants.youth}</span>
              <button
                className={styles.counterButton}
                onClick={() => updateCount("youth", 1)}
              >
                <span className={styles.buttonIcon}>+</span>
              </button>
            </div>
          </div>
        )}

        {/* Child section - conditional rendering */}
        {childPrice > 0 && (
          <div className={styles.participantType}>
            <div className={styles.participantInfo}>
              <div className={styles.participantLabel}>Child</div>
              <div className={styles.ageRange}>Age 2 - 12</div>
              <div className={styles.price}>£{childPrice}</div>
            </div>
            <div className={styles.counterControls}>
              <button
                className={styles.counterButton}
                onClick={() => updateCount("child", -1)}
                disabled={participants.child === 0}
              >
                <span className={styles.buttonIcon}>−</span>
              </button>
              <span className={styles.counterValue}>{participants.child}</span>
              <button
                className={styles.counterButton}
                onClick={() => updateCount("child", 1)}
              >
                <span className={styles.buttonIcon}>+</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Participants;

/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import Scrollbar from "../scrollbar";
import { useDispatch } from "react-redux";
import {
  getAlllevels,
  getMember,
  getMemberAmount,
} from "../../dataSlice/dataSlice";
import UserDashboard from "./UserDashboard";
import TicketSale from "./TicketSale";
import TicketList from "./TicketList";
import Commission from "./Commission";
import "./style.css";
import { GET_TICKET_BY_ALL_USER_ID } from "../../constant/constants";
import Tours from "./tours";
import BookingList from "./BookingList";

const Dashboard = () => {
  const user_id = localStorage.getItem("user_id");
  const userType = localStorage.getItem("user_type");

  const dispatch = useDispatch();
  const [toggle, setToggle] = useState("ticket");

  // Uncomment these lines to use the useEffect hooks
  // useEffect(() => {
  //   dispatch(getMemberAmount());
  //   dispatch(getMember(user_id));
  //   dispatch(getAlllevels());
  // }, [dispatch, user_id]);

  // useEffect(() => {
  //   fetch(`${GET_TICKET_BY_ALL_USER_ID}2`)
  //     .then((res) => res?.json())
  //     .then((data) => setAdminTicket(data?.tickets));
  // }, [dispatch, user_id]);
  const ChangeTigger = (toggleOption) => {
    console.log("toggleOption", toggleOption);
    setToggle(toggleOption);
  };

  return (
    <Fragment>
      <section
        className="px-0 pb-5 px-md-5"
        style={{ backgroundColor: "#EBF0FA" }}
      >
        <div className="row">
          {/* Left Side */}
          <div className="col-lg-3 col-md-12 col-sm-12">
            <div className="row sideBar mx-4 mx-md-0">
              <div
                className="col-md-12 mt-4 fw-bolder col-sm-6 p-2 btn btn-light justify-content-center border border-primary rounded mb-2"
                onClick={() => setToggle("ticket")}
                style={{
                  backgroundColor: toggle === "ticket" ? "#29395B" : "white",
                  color: toggle === "ticket" ? "white" : "#29395B",
                }}
              >
                <span className="h3 px-2">All Tour List</span>
              </div>
              {userType !== "promoter" && (
                <>
                  <div
                    className="col-md-12 col-sm-6 btn btn-light p-2 justify-content-center border border-primary rounded mb-2 text-nowrap"
                    onClick={() => setToggle("withdrawList")}
                    style={{
                      backgroundColor:
                        toggle === "withdrawList" ? "#29395B" : "white",
                      color: toggle === "withdrawList" ? "white" : "#29395B",
                    }}
                  >
                    <span className="h3 px-2">Booking List</span>
                  </div>
                  <div
                    className="col-md-12 col-sm-6 btn btn-light p-2 justify-content-center border border-primary rounded mb-2 text-nowrap"
                    onClick={() => setToggle("commission")}
                    style={{
                      backgroundColor:
                        toggle === "commission" ? "#29395B" : "white",
                      color: toggle === "commission" ? "white" : "#29395B",
                    }}
                  >
                    <span className="h3 px-2">Agent Commission</span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Right Side */}
          <div className="col-lg-9 col-md-12 col-sm-12">
            {/* Dashboard */}
            <div
              className="row col-md-12"
              style={{ display: toggle === "dashboard" ? "block" : "none" }}
            ></div>
            {/* Ticket Sale */}
            <div
              className="row col-md-12"
              style={{ display: toggle === "ticket" ? "block" : "none" }}
            >
              <Tours />
            </div>
            <div
              className="row col-md-12"
              style={{ display: toggle === "withdrawList" ? "block" : "none" }}
            >
              {/* <TicketList /> */}
              <BookingList />
            </div>
            <div
              className="row col-md-12"
              style={{ display: toggle === "commission" ? "block" : "none" }}
            >
              <Commission toggle={toggle === "commission" && true} />
            </div>
            <div className="row col-md-12"></div>
          </div>
        </div>
      </section>
      <Scrollbar />
    </Fragment>
  );
};

export default Dashboard;

import React from "react";

import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ChevronLeft, ChevronRight, Loader2 } from "lucide-react";
import TripReview from "./TripReview";
import SidebarRight from "./SidebarRight";
import {
  BASE_URL_Uk,
  GET_CONTENTS_WITHOUT_PAGINATION_UK,
} from "../../constant/constants";
import AuthGuard from "./../AuthGuard";

const SingleTour = () => {
  const [tour, setTour] = useState(null);
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  // Fetch tour menu ID
  const [tourId, setTourId] = useState(null);

  useEffect(() => {
    fetch(`${BASE_URL_Uk}/cms_menu/api/v1/cms_menu/all/`)
      .then((response) => response.json())
      .then((data) => {
        const tourMenu = data.menus.find((menu) => menu.name === "Tours");
        if (tourMenu) {
          setTourId(tourMenu.id);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Fetch tour data and images
  useEffect(() => {
    if (!tourId) return;
    const fetchTourAndImages = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // First, fetch the tour list using the same approach as Tours.jsx
        const tourListResponse = await fetch(
          GET_CONTENTS_WITHOUT_PAGINATION_UK
        );
        if (!tourListResponse.ok) throw new Error("Failed to fetch tour list");

        const data = await tourListResponse.json();
        const filteredTours = data.menu_items.filter(
          (item) =>
            item.type === "Tours" &&
            !item.name.includes("Colosseum") &&
            !item.name.includes("TechStore")
        );

        // Find the tour with matching slug
        const tourInfo = filteredTours.find((t) => t.slug === slug);
        if (!tourInfo) throw new Error("Tour not found");

        // Now fetch the full tour details using the ID
        const [tourResponse, imagesResponse] = await Promise.all([
          fetch(
            `${BASE_URL_Uk}/cms_menu_content/api/v1/cms_menu_content/${tourInfo.id}`
          ),
          fetch(
            `${BASE_URL_Uk}/cms_menu_content_image/api/v1/get_all_cms_menu_content_image_list_by_cms_menu_id/${tourId}`
          ),
        ]);

        if (!tourResponse.ok) throw new Error("Failed to fetch tour data");
        if (!imagesResponse.ok) throw new Error("Failed to fetch images");

        const tourData = await tourResponse.json();
        const imagesData = await imagesResponse.json();

        setTour(tourData);
        if (imagesData.content_images[tourData.name]) {
          setImages(imagesData.content_images[tourData.name]);
        }
      } catch (error) {
        console.error("Error fetching tour or images:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTourAndImages();
  }, [slug, tourId]);

  // Image navigation handlers
  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const prevImage = useCallback(() => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  }, [images.length]);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") prevImage();
      if (e.key === "ArrowRight") nextImage();
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [nextImage, prevImage]);

  // Loading state
  if (isLoading) {
    return (
      <div className="container min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    );
  }

  // No tour data
  if (!tour) {
    return (
      <div className="container mt-4">
        <div className="alert alert-warning" role="alert">
          Tour not found
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-8">
          {/* Tour Header */}
          <div className="mb-4">
            <h1
              className="text-3xl font-bold mb-3"
              style={{ fontFamily: '"Poppins", sans-serif' }}
            >
              {tour.name}
            </h1>
            <div className="flex items-center gap-2">
              <TripReview title={tour.name.toLowerCase()} />
              <span className="text-muted">({tour.reviews || 0} reviews)</span>
            </div>
          </div>

          {/* Image Gallery */}
          <div className="image-gallery mb-4">
            <div className="main-image-container relative rounded-lg overflow-hidden">
              {images.length > 0 ? (
                <>
                  <img
                    src={images[currentImageIndex] || "/placeholder.svg"}
                    className="main-image"
                    alt={`${tour.name} - View ${currentImageIndex + 1}`}
                    loading="lazy"
                  />
                  {/* Navigation buttons */}
                  <button
                    onClick={prevImage}
                    className="nav-button left"
                    aria-label="Previous image"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <button
                    onClick={nextImage}
                    className="nav-button right"
                    aria-label="Next image"
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                  {/* Image counter */}
                  <div className="image-counter">
                    {currentImageIndex + 1} / {images.length}
                  </div>
                </>
              ) : (
                <img
                  src={tour.cloudflare_image?.[0] || "/placeholder.svg"}
                  className="main-image"
                  alt={tour.name}
                  loading="lazy"
                />
              )}
            </div>

            {/* Thumbnails */}
            {images.length > 1 && (
              <div className="thumbnails-container mt-2">
                {images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImageIndex(index)}
                    className={`thumbnail-button ${
                      index === currentImageIndex ? "active" : ""
                    }`}
                    aria-label={`View image ${index + 1}`}
                  >
                    <img
                      src={image || "/placeholder.svg"}
                      alt={`${tour.name} thumbnail ${index + 1}`}
                      className="thumbnail-image"
                      loading="lazy"
                    />
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Tour Description */}
          <div
            className="tour-description prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: tour.description }}
          />
        </div>

        {/* Sidebar */}
        <div className="col-md-4">
          <SidebarRight tour={tour} />
        </div>
      </div>

      <style jsx>{`
        .image-gallery {
          background: #f8f9fa;
          border-radius: 0.5rem;
          overflow: hidden;
        }

        .main-image-container {
          position: relative;
          aspect-ratio: 16/9;
          background: #e9ecef;
        }

        .main-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .nav-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(255, 255, 255, 0.9);
          border: none;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;
          opacity: 0;
        }

        .main-image-container:hover .nav-button {
          opacity: 1;
        }

        .nav-button:hover {
          background: white;
          transform: translateY(-50%) scale(1.1);
        }

        .nav-button.left {
          left: 1rem;
        }

        .nav-button.right {
          right: 1rem;
        }

        .image-counter {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          background: rgba(0, 0, 0, 0.7);
          color: white;
          padding: 0.25rem 0.75rem;
          border-radius: 1rem;
          font-size: 0.875rem;
        }

        .thumbnails-container {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          padding: 0.5rem;
          justify-content: flex-start;
        }

        .thumbnail-button {
          padding: 0;
          border: 2px solid transparent;
          border-radius: 0.25rem;
          overflow: hidden;
          cursor: pointer;
          transition: all 0.2s ease;
          width: 100px;
          height: 100px;
          flex: 0 0 auto;
        }

        .thumbnail-button.active {
          border-color: #0d6efd;
        }

        .thumbnail-button:hover {
          transform: scale(1.05);
        }

        .thumbnail-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .tour-description {
          line-height: 1.6;
        }

        @media (max-width: 768px) {
          .nav-button {
            opacity: 1;
            width: 32px;
            height: 32px;
          }

          .thumbnails-container {
            justify-content: center;
          }
        }

        @media (max-width: 480px) {
          .thumbnail-button {
            width: 80px;
            height: 80px;
          }
        }
      `}</style>
    </div>
  );
};

export default function ProtectedTours() {
  return (
    <AuthGuard>
      <SingleTour />
    </AuthGuard>
  );
}

import React from "react";

import { useState, useEffect } from "react";
import styles from "./calendar.module.css";
import clsx from "clsx";

const ChooseDate = ({
  onSelectionComplete,
  availableDates,
  price,
  currentCurrency,
}) => {
  // Initialize calendar to show the month of the first available date
  const getInitialDate = () => {
    if (availableDates && availableDates.length > 0) {
      // Sort dates to get the earliest one
      const sortedDates = [...availableDates].sort((a, b) => a - b);
      // Create a new date with the same month and year as the first available date
      return new Date(
        sortedDates[0].getFullYear(),
        sortedDates[0].getMonth(),
        1
      );
    }
    // Default to current month if no available dates
    return new Date();
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showTimeSelection, setShowTimeSelection] = useState(false);
  const [currentDate, setCurrentDate] = useState(getInitialDate);

  const timeSlots = ["07:30 AM"];

  const handleDateClick = (date) => {
    // Ensure the selected date is stored without any unintended timezone conversion
    const localDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      12
    ); // Add 12 hours to prevent UTC shift
    setSelectedDate(localDate);
    setShowTimeSelection(true);

    if (timeSlots.length === 1) {
      setSelectedTime(timeSlots[0]);
    } else {
      setSelectedTime(null);
    }
  };

  useEffect(() => {
    if (selectedDate && timeSlots.length === 1) {
      onSelectionComplete({
        date: selectedDate,
        time: timeSlots[0],
        price,
      });
    }
  }, [selectedDate, onSelectionComplete, price]); // Removed timeSlots from dependencies

  const handleTimeClick = (time) => {
    setSelectedTime(time);
    onSelectionComplete({
      date: selectedDate,
      time: time,
      price,
    });
  };

  const handleBackToCalendar = () => {
    setShowTimeSelection(false);
    setSelectedTime(null);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const changeMonth = (increment) => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + increment);
      return newDate;
    });
  };

  if (showTimeSelection) {
    return (
      <>
        <h2 className={styles.sectionTitle}>
          <button onClick={handleBackToCalendar} className={styles.backButton}>
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          {formatDate(selectedDate)}
        </h2>

        <div className={styles.timeSelectionWrapper}>
          <h3 className={styles.timeSelectionTitle}>
            {timeSlots.length === 1 ? "Selected time" : "Choose a time"}
          </h3>

          <div className={styles.timeGrid}>
            {timeSlots.map((time) => (
              <button
                key={time}
                className={clsx(styles.timeSlot, {
                  [styles.timeSlotSelected]: selectedTime === time,
                })}
                onClick={() => handleTimeClick(time)}
                disabled={timeSlots.length === 1}
              >
                <span className={styles.availabilityIndicator}></span>
                {time}
              </button>
            ))}
          </div>

          <button
            className={styles.backToCalendarLink}
            onClick={handleBackToCalendar}
          >
            Back to calendar
          </button>
        </div>
      </>
    );
  }

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(year, month, 1).getDay();

    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }
    return days;
  };

  const daysInMonth = getDaysInMonth(currentDate);

  const isDateAvailable = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (
      date >= today &&
      availableDates.some(
        (availableDate) =>
          availableDate.getDate() === date.getDate() &&
          availableDate.getMonth() === date.getMonth() &&
          availableDate.getFullYear() === date.getFullYear()
      )
    );
  };

  // Check if there are any available dates in the current month
  const hasAvailableDatesInMonth = () => {
    if (!availableDates || availableDates.length === 0) return false;

    return availableDates.some(
      (date) =>
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
    );
  };

  return (
    <>
      <h2 className={styles.sectionTitle}>Choose a date</h2>

      <div className={styles.calendarWrapper}>
        <div className={styles.monthSelector}>
          <button
            className={styles.monthButton}
            onClick={() => changeMonth(-1)}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <div className="d-flex align-items-center gap-2">
            {currentDate.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
            <i className="icon icon-chevron-down text-12 text-dark"></i>
          </div>
          <button className={styles.monthButton} onClick={() => changeMonth(1)}>
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>

        <div className={styles.weekDays}>
          {weekDays.map((day) => (
            <div key={day} className={styles.weekDay}>
              {day}
            </div>
          ))}
        </div>

        {!hasAvailableDatesInMonth() ? (
          <div className={styles.noAvailabilityMessage}>
            <i className="fa-regular fa-calendar fa-lg mb-3 text-gray-500"></i>
            <p>Your selection is not available for this month</p>
          </div>
        ) : (
          <div className={styles.calendarGrid}>
            <div className="row g-1">
              {daysInMonth.map((date, index) => {
                if (!date) {
                  return (
                    <div
                      key={`empty-${index}`}
                      className={styles.calendarCol}
                    ></div>
                  );
                }
                const isAvailable = isDateAvailable(date);
                return (
                  <div key={date.getTime()} className={styles.calendarCol}>
                    <button
                      className={clsx(styles.calendarBtn, {
                        [styles.active]:
                          selectedDate &&
                          selectedDate.getTime() === date.getTime(),
                        [styles.disabled]: !isAvailable,
                      })}
                      onClick={() => isAvailable && handleDateClick(date)}
                      disabled={!isAvailable}
                    >
                      <span>{date.getDate()}</span>
                      {isAvailable && (
                        <span className={styles.datePrice}>£{price}</span>
                      )}
                      {isAvailable && (
                        <span className={styles.availabilityDot}></span>
                      )}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className="mt-3 small text-muted">
          Showing prices in GBP (Great Britain Pound)
        </div>
      </div>
    </>
  );
};

export default ChooseDate;

import React from "react";

import { useState, useEffect } from "react"
import { Loader2 } from "lucide-react"
import AuthGuard from "../AuthGuard"
import { GET_CONTENTS_BY_MENU_ID, GET_IMAGE_BY_MENU_ID } from './../../constant/constants';

const About = ({ id }) => {
  const [content, setContent] = useState("")
  const [image, setImage] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchContent = async () => {
      // Only fetch if id is available
      if (!id) return

      try {
        setIsLoading(true)
        const [contentResponse, imageResponse] = await Promise.all([
          fetch(
            `${GET_CONTENTS_BY_MENU_ID}/${id}`,
          ),
          fetch(
            `${GET_IMAGE_BY_MENU_ID}/${id}`,
          ),
        ])

        if (!contentResponse.ok || !imageResponse.ok) {
          throw new Error("Failed to fetch content")
        }

        const contentData = await contentResponse.json()
        const imageData = await imageResponse.json()

        setContent(contentData.menu_contents.About)
        setImage(imageData.content_images.About)
      } catch (err) {
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchContent()
  }, [id]) // Re-fetch when id changes

  // Extract the title and paragraphs from the HTML content
  const extractContent = (htmlContent) => {
    if (!htmlContent) return { title: "About Us", paragraphs: [] }

    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, "text/html")

    // Find the title (usually in an h1 tag)
    const title = doc.querySelector("h1") ? doc.querySelector("strong").textContent.trim() : "About Us"

    // Find all paragraphs
    const paragraphs = Array.from(doc.querySelectorAll("p"))
      .map((p) => p.textContent.trim())
      .filter((p) => p)

    return { title, paragraphs }
  }

  if (!id) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin" />
        <span className="ml-2">Loading page information...</span>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-red-500">Error: {error}</div>
      </div>
    )
  }

  const { title, paragraphs } = extractContent(content)

  return (
    <div className="about-page">
      <div className="about-container">
        <div className="about-content">
          <div className="logo-section">
            <div className="decorative-shapes">
              <div className="shape shape-1"></div>
              <div className="shape shape-2"></div>
            </div>
            <img src={`https://apibustours.dreamtourism.co.uk/media/${image}`} alt="Dream Tourism Logo" className="logo" />
            <div className="decorative-dots">
              <div className="dot dot-1"></div>
              <div className="dot dot-2"></div>
              <div className="dot dot-3"></div>
              <div className="dot dot-4"></div>
              <div className="dot dot-5"></div>
            </div>
          </div>

          <div className="content-section">
            <h1 className="title">{title}</h1>

            {paragraphs.map((paragraph, index) => (
              <p key={index} className="paragraph">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .about-page {
          background-color: #fff5f5;
          min-height: 100vh;
          padding: 2rem 1rem;
        }

        .about-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
          background-color: white;
          border-radius: 1rem;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }

        .about-content {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 3rem;
        }

        .logo-section {
          position: relative;
          flex: 0 0 40%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;
        }

        .logo {
          max-width: 100%;
          height: auto;
          position: relative;
          z-index: 2;
        }

        .decorative-shapes {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .shape {
          position: absolute;
          border-radius: 4px;
        }

        .shape-1 {
          width: 40px;
          height: 40px;
          background-color: #4fd1c5;
          top: 20%;
          left: 15%;
          transform: rotate(45deg);
        }

        .shape-2 {
          width: 30px;
          height: 30px;
          background-color: #f56565;
          bottom: 30%;
          left: 25%;
          transform: rotate(30deg);
        }

        .decorative-dots {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }

        .dot {
          width: 8px;
          height: 8px;
          background-color: #f97316;
          border-radius: 50%;
          margin: 8px 0;
        }

        .content-section {
          flex: 1;
          padding: 1rem 0;
        }

        .title {
          color: #f97316;
          font-size: 2.5rem;
          font-weight: bold;
          margin-bottom: 2rem;
        }

        .paragraph {
          margin-bottom: 1.5rem;
          color: #4a5568;
          font-size: 1.1rem;
          line-height: 1.8;
        }

        @media (max-width: 768px) {
          .about-content {
            flex-direction: column;
            gap: 2rem;
          }

          .logo-section {
            flex: 0 0 auto;
            width: 100%;
            padding: 1rem;
          }

          .logo {
            max-width: 300px;
          }

          .decorative-dots {
            right: 10%;
            top: auto;
            bottom: 0;
            transform: none;
            display: flex;
            gap: 8px;
          }

          .dot {
            margin: 0;
          }

          .title {
            font-size: 2rem;
            text-align: center;
          }
        }

        @media (max-width: 480px) {
          .about-container {
            padding: 1rem;
          }

          .logo {
            max-width: 250px;
          }

          .title {
            font-size: 1.75rem;
          }

          .paragraph {
            font-size: 1rem;
          }

          .shape {
            transform: scale(0.6);
          }
        }
      `}</style>
    </div>
  )
}

const ProtectedAbout = ({ id }) => {
  return (
    <AuthGuard>
      <About id={id} />
    </AuthGuard>
  )
}

export default ProtectedAbout


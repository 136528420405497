import React from "react";

import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "./../../constant/constants";

const Commission = () => {
  const agent_id = localStorage.getItem("user_id");
  const [bookingSummary, setBookingSummary] = useState([]);
  const [filterData, setFilterData] = useState({
    date_after: "",
    date_before: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBookingSummary();
  }, []);

  const fetchBookingSummary = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${BASE_URL}/tour_booking/api/v1/tour_booking/booked_data/`,
        {
          params: {
            date_after: filterData.date_after,
            date_before: filterData.date_before,
            agent: agent_id,
          },
        }
      );
      setBookingSummary(response.data.tour_bookings);
    } catch (error) {
      console.error("Error fetching Agent summary:", error);
      setError("Failed to fetch Agent summary. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchBookingSummary();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const totalCommission = bookingSummary.reduce(
    (total, booking) => total + (booking.total_discount_amount || 0),
    0
  );

  const totalBookings = bookingSummary.reduce(
    (total, booking) => total + (booking.booking_count || 0),
    0
  );

  return (
    <div className="mx-3">
      <div className="container mt-4">
        <form onSubmit={handleSubmit} className="mb-4">
          <div className="row mx-0">
            <div className="form-group col-md-3">
              <label>Date From:</label>
              <input
                type="date"
                className="form-control"
                name="date_after"
                value={filterData.date_after}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-3">
              <label>Date To:</label>
              <input
                type="date"
                className="form-control"
                name="date_before"
                value={filterData.date_before}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-2">
              <label>&nbsp;</label>
              <button
                type="submit"
                className="btn btn-primary form-control"
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Filter"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <h3 className="text-center mb-4">
        Agent Commission{" "}
        {bookingSummary.length > 0 && bookingSummary[0]?.members[0]?.name
          ? `- ${bookingSummary[0]?.members[0]?.name}`
          : ""}
      </h3>
      {error && <div className="alert alert-danger">{error}</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="table-container">
        {bookingSummary.length === 0 ? (
          <div className="text-center py-4">There is no Commission</div>
        ) : (
          <table className="commission-table">
            <thead>
              <tr>
                <th>SL.</th>
                <th>Tour Name</th>
                <th>Total Price</th>
                <th>Total Commission Amount</th>
                <th>Commission</th>
              </tr>
            </thead>
            <tbody>
              {bookingSummary.map((booking, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{booking.tour_name || "N/A"}</td>
                  <td>{booking.total_price}</td>
                  <td>{booking.total_discount_amount}</td>
                  <td>{booking.discount_percent}%</td>
                </tr>
              ))}
            </tbody>
            {bookingSummary.length !== 0 && (
              <tfoot>
                <tr>
                  <td colSpan="3">Total Commission:</td>
                  <td>{Number(totalCommission).toFixed(2)}</td>
                  <td colSpan="2"></td>
                </tr>
              </tfoot>
            )}
          </table>
        )}
      </div>

      <style jsx>{`
        .table-container {
          background: white;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          overflow: auto;
          max-height: 500px;
        }

        .commission-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
          background: white;
        }

        .commission-table th,
        .commission-table td {
          padding: 16px;
          text-align: center;
          border: 1px solid #e2e8f0;
          white-space: nowrap;
        }

        .commission-table th {
          background: #1a1a1a;
          color: white;
          font-weight: 600;
          position: sticky;
          top: 0;
          z-index: 1;
          border-bottom: 2px solid #2d3748;
        }

        .commission-table tbody tr:nth-child(even) {
          background-color: #f8fafc;
        }

        .commission-table tbody tr:hover {
          background-color: #edf2f7;
        }

        .commission-table tfoot {
          font-weight: 600;
          background-color: #f1f5f9;
        }

        .commission-table tfoot td {
          border-top: 2px solid #2d3748;
        }

        /* Ensure last row's bottom border is visible */
        .commission-table tbody tr:last-child td {
          border-bottom: 1px solid #e2e8f0;
        }

        /* Add vertical grid lines */
        .commission-table th:not(:last-child),
        .commission-table td:not(:last-child) {
          border-right: 1px solid #e2e8f0;
        }

        /* Add horizontal grid lines */
        .commission-table tbody tr:not(:last-child) td {
          border-bottom: 1px solid #e2e8f0;
        }

        /* Style the scrollbar */
        .table-container::-webkit-scrollbar {
          width: 12px;
          height: 12px;
        }

        .table-container::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 6px;
        }

        .table-container::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 6px;
          border: 3px solid #f1f1f1;
        }

        .table-container::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
};

export default Commission;

import React from "react";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AgentCalendar from "./AgentCalendar";
import {
  bus_id_booking,
  get_cms_menu_content_slug_UK,
} from "../../constant/constants";
import { GET_BUS_INFO_DETAIL_BY_BUS_ID } from "./../../constant/constants";

const SidebarRight = ({ tour }) => {
  const [busData, setBusData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${get_cms_menu_content_slug_UK}${tour.slug}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tour data");
        }
        const data = await response.json();

        // if (data.select_bus) {
        const busResponse = await fetch(
          `${bus_id_booking}${data.select_bus}`
          // `https://apibustours.dreamtourism.it/bus/api/v1/bus/25`
        );
        if (!busResponse.ok) {
          throw new Error("Failed to fetch bus data");
        }
        const busData = await busResponse.json();
        console.log("busData", busData);
        setBusData(busData);
        // }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [tour.slug]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="d-flex js-pin-content" style={{ height: "fit-content" }}>
      <div className="lg:w-full d-flex flex-column ml-10">
        <div className="d-flex items-center gradient-text">
          <img
            src="https://imagedelivery.net/dIKhvGtesTiRSxhQ2oKWkA/863921a6-6870-4690-d6bd-8dd0f0314f00/public"
            width={60}
            height={22}
            alt="Book now"
            className="ms-3 me-3 p-1"
          />
          <span className="fw-500 ml-20">Book now, pay in 3 installments</span>
        </div>

        <AgentCalendar
          tourSlug={tour.slug}
          busId={busData.id}
          availableDates={busData.available_dates}
          price={tour.price}
          adultPrice={busData.adult_seat_price}
          childPrice={busData.child_seat_price}
          youthPrice={busData.youth_seat_price}
          data={busData}
          data2={tour}
        />
      </div>
    </div>
  );
};

export default SidebarRight;

import React from "react";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckCircle } from "lucide-react";
import {
  GET_SITESETTINGS,
  tour_booking_list_by_agent_all,
} from "../../constant/constants";
import { payment_details, BASE_URL } from "./../../constant/constants";
import AuthGuard from "../AuthGuard";

const BookingSuccess = () => {
  //LOGO
  const [siteSetting, setSiteSetting] = useState({});

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      // Get payment_id from URL using URLSearchParams
      const urlParams = new URLSearchParams(window.location.search);
      const payment_id = urlParams.get("payment_id");

      if (!payment_id) {
        setError("Payment ID not found");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${payment_details}${payment_id}/`);
        if (!response.ok) {
          throw new Error("Failed to fetch payment details");
        }
        const data = await response.json();
        setPaymentDetails(data.payment_details);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentDetails();
  }, []); // No dependency on searchParams anymore
  console.log("payment", paymentDetails);

  const getHref = (path) => {
    if (paymentDetails?.agent_ref_no) {
      return `${path}?agentRef=${paymentDetails.agent_ref_no}`;
    }
    return path;
  };

  if (isLoading) {
    return <div className="text-center py-3">Loading Booking details...</div>;
  }

  if (error) {
    return <div className="text-center py-3 text-danger">Error: {error}</div>;
  }

  console.log("invoice", `${BASE_URL}${paymentDetails.booking_invoice}`);

  return (
    <>
      <div className="header-margin"></div>
      <main className="main-content">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="card border-0 shadow-sm">
                <div className="card-body text-center p-4">
                  <div className="mb-3">
                    <CheckCircle className="text-success" size={60} />
                  </div>

                  <h1 className="h3 fw-bold text-success mb-3">
                    Booking Successful!
                  </h1>

                  <div className="mb-3">
                    <img
                      style={{
                        width: "150px",
                      }}
                      src={`${BASE_URL}${siteSetting.footer_logo}`}
                      alt="blog"
                      loading="lazy"
                    />
                  </div>

                  <p className="text-muted mb-4">
                    Thank you for booking with Dream Tourism. Your payment has
                    been processed successfully. A confirmation email has been
                    sent to your registered email address.
                  </p>

                  <div className="border-top border-bottom py-3 mb-3">
                    <div className="row">
                      <div className="col-6 border-end">
                        <div className="text-muted small">
                          Booking Reference
                        </div>
                        <div className="fw-bold">
                          {paymentDetails?.id || "N/A"}
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="text-muted small">Amount Paid</div>
                        <div className="fw-bold">
                          {paymentDetails?.currency?.symbol}
                          {paymentDetails?.amount || "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-grid gap-2">
                    <Link
                      to={getHref("/")}
                      className="btn btn-outline-secondary"
                    >
                      Return to Home
                    </Link>
                  </div>

                  <div className="mt-3">
                    <p className="small text-muted mb-0">
                      Having trouble?{" "}
                      <Link
                        to={getHref("/contact")}
                        className="text-decoration-none"
                      >
                        Contact our support team
                      </Link>
                    </p>
                  </div>

                  {/* Additional Order Details Section */}
                  <div className="mt-4 text-start">
                    <h3 className="h5 mb-3">Booking Details</h3>
                    <div className="bg-light p-3 rounded">
                      <div className="row g-2">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Tourist Name</span>
                            <span className="fw-medium">
                              {paymentDetails?.traveller || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Tour Name</span>
                            <span className="fw-medium">
                              {paymentDetails?.tour || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Payment key</span>
                            <span className="fw-medium">
                              {paymentDetails?.payment_key || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Amount</span>
                            <span className="fw-medium">
                              {paymentDetails?.currency?.symbol}
                              {paymentDetails?.amount || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Currency</span>
                            <span className="fw-medium">
                              {paymentDetails?.currency?.currency_code || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Payment Method</span>
                            <span className="fw-medium">
                              {paymentDetails?.payment_method || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Payment Status</span>
                            <span className="fw-medium">
                              {paymentDetails?.payment_status || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <span className="text-muted">Agent Reference</span>
                            <span className="fw-medium">
                              {paymentDetails?.agent_ref_no || "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Download Receipt Button */}
                  <div className="mt-3">
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = `${BASE_URL}${paymentDetails.booking_invoice}`;
                        link.download = paymentDetails.booking_invoice
                          .split("/")
                          .pop(); // Extracts file name
                        link.target = "_blank";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <i className="bi bi-download me-2"></i>
                      Download Receipt
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};



export default function ProtectedBookingSuccess() {
  return (
    <AuthGuard>
      <BookingSuccess />
    </AuthGuard>
  );
}

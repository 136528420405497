import React from "react"

import { Fragment, useEffect, useState } from "react"
import PageTitle from "../../components/pagetitle"
import Footer from "../../components/footer"
import Scrollbar from "../../components/scrollbar"
import { GET_MENUS_ALL_NESTED } from "../../constant/constants"
import ProtectedAbout from './../../components/AboutUs/about';
import AuthGuard from "../../components/AuthGuard"

const AboutPage = () => {
  const [aboutId, setAboutId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchMenuId = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(GET_MENUS_ALL_NESTED)

        if (!response.ok) {
          throw new Error("Failed to fetch menu data")
        }

        const data = await response.json()

        // Find the About menu and set its ID
        const aboutMenu = data.menus.find((e) => e.name === "About")
        if (aboutMenu) {
          setAboutId(aboutMenu.id)
        } else {
          throw new Error("About menu not found")
        }
      } catch (err) {
        console.error("Error fetching menu data:", err)
        setError(err.message)
      } finally {
        setIsLoading(false)
      }
    }

    fetchMenuId()
  }, [])

  return (
    <Fragment>
      <PageTitle pageTitle={"About Us"} pagesub={"About"} />
      {error ? (
        <div className="container mx-auto py-8 text-center">
          <div className="text-red-500">Error: {error}</div>
        </div>
      ) : (
        <ProtectedAbout id={aboutId} />
      )}
      <Footer />
      <Scrollbar />
    </Fragment>
  )
}


export default function ProtectedAboutPage() {
  return (
    <AuthGuard>
      <AboutPage />
    </AuthGuard>
  );
}


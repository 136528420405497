import React, { useEffect, useState } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import { BASE_URL, GET_SITESETTINGS } from "../../constant/constants";
import { Helmet, HelmetProvider } from "react-helmet-async";
import store from "../../dataSlice";
import { Provider } from "react-redux";

const App = () => {
  const [siteSetting, setSiteSetting] = useState({});

  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        setSiteSetting(data.general_settings[0]);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.href = `${BASE_URL}${siteSetting.favicon}`;
  }, [siteSetting.favicon]);

  return (
    <div className='App' id='scrool'>
      <HelmetProvider>
        <Helmet>
          <title>
            {siteSetting.title ? siteSetting.title : "Agent Tour Booking"}
          </title>
        </Helmet>
        <Provider store={store}>
          {" "}
          <AllRoute logo={siteSetting.logo} />{" "}
        </Provider>
        <ToastContainer />
      </HelmetProvider>
    </div>
  );
};

export default App;
